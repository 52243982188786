import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { CarouselComponent } from './carousel/carousel.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { ServicesComponent } from './services/services.component';
import { AourteamComponent } from './aourteam/aourteam.component';
import { CoursesComponent } from './courses/courses.component';
import { WebpagRoutingModule } from './webpage-routing.module';
import { HomeComponent } from './home/home.component';
import { CourseComponent } from './course/course.component';
import { ClientsComponent } from './clients/clients.component';
import { ServiceDetailComponent } from './serviceDetail/serviceDetail.component';
import { ServiceDetail2Component } from './serviceDetail2/serviceDetail2.component';
import { ServiceDetail3Component } from './serviceDetail3/serviceDetail3.component';
import { Course1Component } from './course1/course1.component';
import { Course2Component } from './course2/course2.component';
import { Course3Component } from './course3/course3.component';

import { HttpClientModule } from '@angular/common/http';

import { AngularFontAwesomeModule } from 'angular-font-awesome';


import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    CarouselComponent,
    AboutComponent,
    ContactComponent,
    FooterComponent,
    ServicesComponent,
    AourteamComponent,
    CoursesComponent,
    HomeComponent,
    CourseComponent,
    ClientsComponent,
    ServiceDetailComponent,
    ServiceDetail2Component,
    ServiceDetail3Component,
    Course1Component,
    Course2Component,
    Course3Component
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    WebpagRoutingModule,
    AngularFontAwesomeModule
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
