import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoursesComponent } from './courses/courses.component';
import { HomeComponent } from './home/home.component';
import { CourseComponent } from './course/course.component';
import { Course1Component } from './course1/course1.component';
import { Course2Component } from './course2/course2.component';
import { Course3Component } from './course3/course3.component';
import { ServiceDetailComponent } from './serviceDetail/serviceDetail.component';
import { ServiceDetail2Component } from './serviceDetail2/serviceDetail2.component';
import { ServiceDetail3Component } from './serviceDetail3/serviceDetail3.component';

const routes: Routes = [
  {
    path: 'courses',
    component: CoursesComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    //path: 'course/:id',
    path: 'course',
    component: CourseComponent
  },
  {
    //path: 'course/:id',
    path: 'course1',
    component: Course1Component
  },
  {
    //path: 'course/:id',
    path: 'course2',
    component: Course2Component
  },
  {
    //path: 'course/:id',
    path: 'course3',
    component: Course3Component
  },
  {
    path: 'serviceDetail',
    component: ServiceDetailComponent
  },
  {
    path: 'serviceDetail2',
    component: ServiceDetail2Component
  },
  {
    path: 'serviceDetail3',
    component: ServiceDetail3Component
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo : 'home'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class WebpagRoutingModule {}
