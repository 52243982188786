import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Course } from '../common/models/course';
import * as _ from 'lodash';

@Component({
  selector: 'app-course-2',
  templateUrl: './course2.component.html',
  styleUrls: ['./course2.component.css']
})
export class Course2Component implements OnInit {
  public id: number;
  public course: Course;

  constructor(private route: ActivatedRoute, private http: HttpClient) {
    // this.getCourse().subscribe((data: any) => {
    //   const courses = _.filter(data.courses, (course: Course) => {
    //     return course.id === this.id;
    //   });
    //   this.course = courses[0];
    // });
  }

  ngOnInit() {
    // this.route.params.subscribe(params => {
    //   this.id = +params['id'];
    // });
  }
  public getCourse() {
    //return this.http.get('../../assets/data/courses/courses.json');
  }
   ngAfterViewChecked(): void {
   //Called after every check of the component's view. Applies to components only.
   //Add 'implements AfterViewChecked' to the class.
   window.scrollTo(0, 0);
 }
}
