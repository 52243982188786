import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-detail',
  templateUrl: './serviceDetail.component.html',
  styleUrls: ['./serviceDetail.component.css']
})
export class ServiceDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
 ngAfterViewChecked(): void {
   //Called after every check of the component's view. Applies to components only.
   //Add 'implements AfterViewChecked' to the class.
   window.scrollTo(0, 0);
 }
}
