import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-detail-3',
  templateUrl: './serviceDetail3.component.html',
  styleUrls: ['./serviceDetail3.component.css']
})
export class ServiceDetail3Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }
   ngAfterViewChecked(): void {
   //Called after every check of the component's view. Applies to components only.
   //Add 'implements AfterViewChecked' to the class.
   window.scrollTo(0, 0);
 }

}
