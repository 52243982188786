import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Course } from '../common/models/course';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {
  public courses: Course[] = [];

  constructor(private http: HttpClient) {
    this.getCourses().subscribe((data: any) => {
      this.courses = data.courses;
    });
  }
  ngOnInit() {}
  public getCourses() {
    return this.http.get('../../assets/data/courses/courses.json');
  }
   ngAfterViewChecked(): void {
   //Called after every check of the component's view. Applies to components only.
   //Add 'implements AfterViewChecked' to the class.
   window.scrollTo(0, 0);
 }
}
